import React from 'react'
import { Logo } from '../components/logo'

const LoginLeftPanel = () => (
  <div className="h-screen bg-white p-6" style={{minWidth: '400px'}}>
    <Logo textFill="rgb(51, 0, 51)"/>

  </div>
)

const Login = () => (
  <div className="w-full h-screen flex bg-red-100">
    <LoginLeftPanel/>
  </div>
)

export default Login
